import { STATIC_FILES } from "../../model/const";
const { background } = STATIC_FILES;

export const Noise = () => {
  return (
    <>
      <div className='fixed inset-0 -z-10 h-[100vh]  flex-none bg-blackMain'>
        <div
          style={{ backgroundImage: `url(${background})`, backgroundSize: "128px" }}
          className='h-full min-h-full bg-repeat opacity-[0.08]'
        ></div>
      </div>
    </>
  );
};
