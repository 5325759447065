import React, { memo, Suspense } from "react";
import { AnimatePresence, domAnimation, LazyMotion } from "motion/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Outlet, Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";
import { lazy } from "react";

const ProfilePage = lazy(() =>
  import("./pages/profiles/ProfilePage").then((module) => ({ default: module.ProfilePage })),
);
const Loader = lazy(() => import("./pages/ux/Loader").then((module) => ({ default: module.Loader })));
const ProfilePageOutlet = lazy(() =>
  import("./pages/profiles/ProfilePage").then((module) => ({ default: module.ProfilePageOutlet })),
);
const MobileProfilePage = lazy(() =>
  import("./pages/profiles/MobileProfilePage").then((module) => ({ default: module.MobileProfilePage })),
);
const MobileProjectsPage = lazy(() =>
  import("./pages/projects/MobileProjectPage").then((module) => ({ default: module.MobileProjectsPage })),
);
const ProfilesPage = lazy(() =>
  import("./pages/profiles/ProfilesPage").then((module) => ({ default: module.ProfilesPage })),
);
const ErrorPage404 = lazy(() => import("./pages/ux/ErrorPage404").then((module) => ({ default: module.ErrorPage404 })));
const ProfileEditPage = lazy(() =>
  import("./pages/profiles/ProfileEditPage").then((module) => ({ default: module.ProfileEditPage })),
);
const ProfileSecurityPage = lazy(() =>
  import("./pages/profiles/ProfileSecurityPage").then((module) => ({ default: module.ProfileSecurityPage })),
);
const ProfileProjectsPage = lazy(() =>
  import("./pages/profiles/ProfileProjectsPage").then((module) => ({ default: module.ProfileProjectsPage })),
);
const RegistrationPage = lazy(() =>
  import("./pages/registration/RegistrationPage").then((module) => ({ default: module.RegistrationPage })),
);
const LoginPage = lazy(() =>
  import("./pages/registration/LoginPage").then((module) => ({ default: module.LoginPage })),
);
const SecondRegistrationPage = lazy(() =>
  import("./pages/registration/SecondRegistrationPage").then((module) => ({ default: module.SecondRegistrationPage })),
);
const ChangePasswordPage = lazy(() =>
  import("./pages/registration/ChangePasswordPage").then((module) => ({ default: module.ChangePasswordPage })),
);
const NewPasswordPage = lazy(() =>
  import("./pages/registration/NewPasswordPage").then((module) => ({ default: module.NewPasswordPage })),
);
const CodePage = lazy(() => import("./pages/registration/CodePage").then((module) => ({ default: module.CodePage })));
const ProjectsPage = lazy(() =>
  import("./pages/projects/ProjectsPage").then((module) => ({ default: module.ProjectsPage })),
);
const Header = lazy(() => import("./components/shared/Header").then((module) => ({ default: module.Header })));
const Footer = lazy(() => import("./components/shared/Footer").then((module) => ({ default: module.Footer })));
const WelcomePage = lazy(() =>
  import("./pages/registration/WelcomePage").then((module) => ({ default: module.WelcomePage })),
);
const LandingPage = lazy(() =>
  import("./pages/landing/LandingPage").then((module) => ({ default: module.LandingPage })),
);
const ScrollToTop = lazy(() =>
  import("./helpers/utils/ScrollToTop").then((module) => ({ default: module.ScrollToTop })),
);
const ProjectPage = lazy(() =>
  import("./pages/projects/ProjectPage").then((module) => ({ default: module.ProjectPage })),
);
const ValidLink = lazy(() => import("./helpers/utils/ValidLink").then((module) => ({ default: module.ValidLink })));
const CreateProject = lazy(() =>
  import("./pages/projects/CreateProject").then((module) => ({ default: module.CreateProject })),
);
const ProjectEditPage = lazy(() =>
  import("./pages/projects/ProjectEditPage").then((module) => ({ default: module.ProjectEditPage })),
);
const ToastContainer = lazy(() =>
  import("./pages/ux/ToastContainer/ToastContainer").then((module) => ({ default: module.ToastContainer })),
);
const PrivateRoute = lazy(() =>
  import("./helpers/utils/PrivateRoute").then((module) => ({ default: module.PrivateRoute })),
);
const AuthProvider = lazy(() => import("./api/AuthProvider").then((module) => ({ default: module.AuthProvider })));

import { useDeviceWidth } from "./helpers/hooks/useDeviceWidth";
import { Noise } from "./components/shared/Noise";

const PageOutlet = () => {
  const location = useLocation();

  return (
    <div className={`relative flex  flex-col text-white ${location.pathname !== "/" && "bg-blackMain"} max-w-screen`}>
      {location.pathname === "/" && <Noise></Noise>}
      <Header />
      <div className='z-10 min-h-[100dvh]'>
        <AnimatePresence>
          <Outlet />
        </AnimatePresence>
      </div>
      <Footer />
    </div>
  );
};

const RegistrationOutlet = () => {
  return (
    <>
      <Noise></Noise>
      <div className='z-10 flex h-[100vh] flex-col items-center justify-center'>
        <Outlet />
      </div>
    </>
  );
};

export const Routing = memo(() => {
  const location = useLocation();
  const deviceWidth = useDeviceWidth();
  return (
    <Routes location={location} key={location.pathname}>
      <Route path='*' element={<ErrorPage404 />} />

      <Route path='/' element={<RegistrationOutlet />}>
        <Route path='/' element={<ValidLink />}>
          <Route path='/greetings' element={<WelcomePage />} />
          <Route path='/change_password' element={<NewPasswordPage />} />
          <Route path='/registration' element={<RegistrationPage />} />
          <Route path='/registration2' element={<SecondRegistrationPage />} />
          <Route path='/code' element={<CodePage />} />
        </Route>

        <Route path='/login' element={<LoginPage />} />
        <Route path='/password' element={<ChangePasswordPage />} />
      </Route>

      <Route path='' element={<PageOutlet />}>
        <Route path='/' index element={<LandingPage />} />
        <Route path='/profiles' element={<ProfilesPage />} />
        <Route path='/profiles/:profileId' element={<ProfilePage />} />
        <Route path='/projects' element={<ProjectsPage />} />
        <Route path='/projects/:projectId' element={<ProjectPage />} />

        <Route element={<PrivateRoute />}>
          <Route path='/create_project' element={<CreateProject />} />
          <Route
            path='/projects/:projectId/edit'
            element={deviceWidth <= 640 ? <MobileProjectsPage /> : <ProjectEditPage />}
          ></Route>
          <Route path='/myProfile' element={<ProfilePageOutlet />}>
            {deviceWidth > 640 ? (
              <>
                <Route path='/myProfile/projects' element={<ProfileProjectsPage />} />
                <Route index path='/myProfile/edit' element={<ProfileEditPage />} />
                <Route path='/myProfile/security' element={<ProfileSecurityPage />} />
              </>
            ) : (
              <>
                <Route index path='/myProfile/edit' element={<MobileProfilePage />} />
                <Route path='/myProfile/projects' element={<ProfileProjectsPage />} />
              </>
            )}
          </Route>
        </Route>
      </Route>
    </Routes>
  );
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: 1000 * 60 * 10,
      staleTime: 1000 * 60 * 60 * 24,
      gcTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});
export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Loader />}>
        <AuthProvider>
          <LazyMotion features={domAnimation}>
            <Router>
              <ScrollToTop />
              <Routing />
            </Router>
          </LazyMotion>
        </AuthProvider>
        <ToastContainer />
      </Suspense>
    </QueryClientProvider>
  );
}
