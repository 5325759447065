import { ISelectItem, Status, Tag } from "../types";
export const ITEMS_PER_PAGE = 14;

const URL_CDN_SERVER = "https://cdn.styleru.org";

export const STATIC_FILES = {
  leonid: `${URL_CDN_SERVER}/img/leonid.webp`,
  ivan: `${URL_CDN_SERVER}/img/ivan.webp`,
  darya: `${URL_CDN_SERVER}/img/darya.webp`,
  beata: `${URL_CDN_SERVER}/img/beata.webp`,
  vova: `${URL_CDN_SERVER}/img/vova.webp`,
  katya: `${URL_CDN_SERVER}/img/katya.webp`,
  andrew: `${URL_CDN_SERVER}/img/andrew.webp`,
  ruslana: `${URL_CDN_SERVER}/img/ruslana.webp`,
  ceo: `${URL_CDN_SERVER}/img/ceo.webp`,
  manager: `${URL_CDN_SERVER}/img/manager.webp`,
  web: `${URL_CDN_SERVER}/img/web.webp`,
  mobileManager: `${URL_CDN_SERVER}/img/MarketingMobileCursor.webp`,
  mobileWeb: `${URL_CDN_SERVER}/img/BlueWebMobileCursor.webp`,
  background: `${URL_CDN_SERVER}/img/background.png`,
  blueWebMobileCursor: `${URL_CDN_SERVER}/img/BlueWebMobileCursor.webp`,
  MarketingMobileCursor: `${URL_CDN_SERVER}/img/MarketingMobileCursor.webp`,
  greenPic: `${URL_CDN_SERVER}/img/greenPic.webp`,
  redPic: `${URL_CDN_SERVER}/img/redPic.webp`,
  bluePic: `${URL_CDN_SERVER}/img/bluePic.webp`,
  projectDefault: `${URL_CDN_SERVER}/img/projectDefault.webp`,
  logos: `${URL_CDN_SERVER}/img/logos.webp`,

  ufcLogo: `${URL_CDN_SERVER}/icons/ufcLogo.svg`,
  yandexLogo: `${URL_CDN_SERVER}/icons/yandexLogo.svg`,
  alfaLogo: `${URL_CDN_SERVER}/icons/alfaLogo.svg`,
  icon01: `${URL_CDN_SERVER}/icons/01icon.svg`,
  icon02: `${URL_CDN_SERVER}/icons/02icon.svg`,
  icon03: `${URL_CDN_SERVER}/icons/03icon.svg`,
  icon04: `${URL_CDN_SERVER}/icons/04icon.svg`,

  CEOCursor: `${URL_CDN_SERVER}/LeadsCursors/CEO.png`,
  CPOCursor: `${URL_CDN_SERVER}/LeadsCursors/CPO.png`,
  CTOCursor: `${URL_CDN_SERVER}/LeadsCursors/CTO.png`,
  DesignLeadCursor: `${URL_CDN_SERVER}/LeadsCursors/Design-Lead.png`,
  MobileLeadCursor: `${URL_CDN_SERVER}/LeadsCursors/Mobile-Lead.png`,
  MarketingLeadCursor: `${URL_CDN_SERVER}/LeadsCursors/Marketing-Lead.png`,
  SMMLeadCursor: `${URL_CDN_SERVER}/LeadsCursors/SMM-Lead.png`,
  EventLeadCursor: `${URL_CDN_SERVER}/LeadsCursors/Event-Lead.png`,
};

export const ROLES_FILTERS = [
  { label: "Участник", value: "participants" },
  { label: "Руководство", value: "leaders" },
];

export const DEPARTMENT_OPTIONS: ISelectItem<Tag>[] = [
  {
    value: "SMM",
    label: "SMM",
  },
  {
    value: "Marketing",
    label: "Маркетинг",
  },
  {
    value: "Web",
    label: "Web-разработка",
  },
  {
    value: "Design",
    label: "Дизайн",
  },
  {
    value: "Management",
    label: "Менеджмент",
  },
  {
    value: "iOS",
    label: "iOS-разработка",
  },
  {
    value: "Android",
    label: "Android-разработка",
  },
  {
    value: "Event",
    label: "Event",
  },
];

export const STATUS_OPTIONS: ISelectItem<Status>[] = [
  {
    value: "archive",
    label: "Архив",
  },
  {
    value: "development",
    label: "В разработке",
  },
  {
    value: "production",
    label: "Продакшн",
  },
  {
    value: "release",
    label: "Релиз",
  },
];

export const C_LEVELS = [
  {
    id: 1,
    name: "Леонид Горохов",
    photo: STATIC_FILES.leonid,
    position: "Глава организации",
    tg: "bigchocopoppie",
    role: "CEO",
  },
  {
    id: 2,
    name: "Иван Беседин",
    photo: STATIC_FILES.ivan,
    position: "CTO",
    tg: "go00golo0",
    role: "CTO",
  },
  {
    id: 3,
    name: "Дарья Овсянникова",
    photo: STATIC_FILES.darya,
    position: "Арт-директор",
    tg: "dosvidariaaaa",
    role: "Design-Lead",
  },
  {
    id: 4,
    name: "Беата Белова",
    photo: STATIC_FILES.beata,
    position: "Marketing$nbsp;Lead",
    tg: "kipish_manager",
    role: "Marketing-Lead",
  },
  {
    id: 5,
    name: "Владимир Мацнев",
    photo: STATIC_FILES.vova,
    position: "Mobile Lead",
    tg: "retr0brite",
    role: "Mobile-Lead",
  },
  {
    id: 6,
    name: "Катя Выдренкова",
    photo: STATIC_FILES.katya,
    position: `Management$nbsp;Lead`,
    tg: "vekaterinavv",
    role: "CPO",
  },
  {
    id: 7,
    name: "Андрей Акимов",
    photo: STATIC_FILES.andrew,
    position: "Lead SMM & PR",
    tg: "andruu6a",
    role: "SMM-Lead",
  },
  {
    id: 8,
    name: "Агаджанян Руслана",
    photo: STATIC_FILES.ruslana,
    position: "Event Lead",
    tg: "aga_ruslana",
    role: "Event-Lead",
  },
];

export const LANDING_PROJECTS = [
  {
    title: "Homeet",
    description:
      "Сервис, который поможет легко найти квартиру или&nbsp;комнату для&nbsp;совместного проживания студентам Вышки",
    link: "https://styleru.org/projects/4",
  },
  {
    title: "Сайт Стилеру",
    description: "Узнайте больше об&nbsp;участниках и&nbsp;проектах организации",
    link: "https://styleru.org/projects/1",
  },
  {
    title: "Termeet",
    description: "Инструмент для совместного планирования встреч, поможет планировать ваше время проще и&nbsp;быстрее",
    link: "https://styleru.org/projects/5",
  },
  {
    title: "SSL",
    description:
      "Сервис, где можно записаться на занятие в клуб переговоров и&nbsp;узнать информацию о&nbsp;нём и&nbsp;его&nbsp;правилах",
    link: "https://styleru.org/projects/6",
  },
  {
    title: "SMM",
    description:
      "В наших социальных сетях регулярно приглашаем на&nbsp;лекции и&nbsp;митапы от&nbsp;спикеров с&nbsp;огромным опытом, делимся познавательным контентом и&nbsp;рассказываем о&nbsp;том, что&nbsp;происходит внутри Стилеру",
    link: "https://styleru.org/projects/7",
  },
];
export const METRICS = [
  { start: 5, end: 40, suffix: "+", label: "Активных участников", width: "110" },
  { start: 150, end: 350, suffix: "+", label: "Выпускников Организации", width: "150" },
  { start: 5, end: 100, suffix: "+", label: "Часов практики", width: "130" },
  { start: 5, end: 20, suffix: "k+", label: "Завершённых задач в&nbsp;таск-трекерах", width: "164" },
  { start: 5, end: 100, suffix: "+", label: "Завершённых проектов", width: "130" },
];
